import React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Navbar from "../comp/Navbar";
import api from "../servicios/api";
import InfoIcon from "@mui/icons-material/Info";
import Swal from "sweetalert2";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

class Cargar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tip_user: 2,
      Data: [],
      error: null,
      success: null,
      cargando: null,
      name: "",
      visitas: "",
      documento: "",
      file: "",
      num_registros: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const CargoId = cookie.get("CargoId");
    if (CargoId === "2") {
      window.location.href = "./#/home";
    }
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
    if (target.name === "documento") {
      //console.log(target.files[0]);
      this.setState({ file: target.files[0] });
    }
  }

  handleSubmit(event) {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    this.setState({
      error: null,
      success: null,
      cargando: 1,
    });
    const formData = new FormData();
    formData.append("file", this.state.file);

    //inicio
    const esto = this;
    axios({
      url: api + "Excel/?subir_exel=1",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.success === true) {
          esto.setState({
            error: null,
            success: 1,
            cargando: null,
            num_registros: response.data.num_guar,
          });
          if (response.data.est_guar === true) {
            esto.setState({
              Data: response.data.data,
            });
          }
        } else {
          esto.setState({
            error: "error al cargar datos",
            success: null,
            cargando: null,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    //fin

    event.preventDefault();
  }

  render() {
    const { Data } = this.state;
    return (
      <div className="inicio">
        <Navbar />
        <div className="niveles">
          <br></br>
          <Link to="/home" className="btn btn-info">
            <KeyboardArrowLeftIcon /> VOLVER
          </Link>
          <br></br>
          <div className="ni-titulo">
            <h1>CARGAR DATOS</h1> <i>(Excel)</i>
          </div>
          <div className="ni-boxes">
            <div className="ni-subboxes">
              <form className="form" onSubmit={this.handleSubmit}>
                <h3>AGREGAR EXCEL</h3>
                <hr></hr>
                <input
                  type="file"
                  required
                  name="documento"
                  className="form-control-file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={this.handleChange}
                  value={this.state.documento}
                ></input>
                <br></br>
                {this.state.cargando ? (
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-primary" role="alert">
                        CARGANDO
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.success ? (
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-success" role="alert">
                        REGISTRO DE PUNTOS CORRECTOS{" "}
                        <i>TOTAL GUARDADOS {this.state.num_registros}</i>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.error ? (
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <button className="btn btn-success">
                  <SaveIcon /> GUARDAR
                </button>
              </form>
            </div>
            <div className="ni-subboxes">
              <div className="table-responsive form">
                <h3>LISTA DE PUNTOS SUBIDOS</h3>
                <hr></hr>
                <table className="table">
                  <thead>
                    <tr>
                      <td>FECHA</td>
                      <td>DNI</td>
                      <td>MONTO</td>
                      <td>PUNTOS</td>
                      <td>TOTAL PUNTOS</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>{Datos.fecha}</td>
                        <td>{Datos.dni}</td>
                        <td>{Datos.monto}</td>
                        <td>{Datos.puntos}</td>
                        <td>{Datos.new_puntos}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cargar;
