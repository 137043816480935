import React from "react";
import "../../css/estiloredes.css";

export default function Redes() {
    return (

        <div class="shared-panel">
            <a target="_blank" className="shared" title="Facebook" href="https://www.facebook.com/coulantcupcakes/"><img src="img/redes/facebook.png" class="imgredes" alt="logo coulant facebook"></img></a>
            <a target="_blank" className="shared" title="Instagram+" href="https://www.instagram.com/coulantcupcakes/"><img src="img/redes/instagram.png" class="imgredes"></img></a>
            <a target="_blank" className="shared" title="Instagram+" href="https://www.youtube.com/@coulantcupcakescoffees6601"><img src="img/redes/youtube.png" class="imgredes"></img></a>
            <a target="_blank" className="shared" title="Instagram+" href="https://www.tiktok.com/@coulantcupcake"><img src="img/redes/tik-tok.png" class="imgredes"></img></a>
        </div>
    );
}

