import React from "react";
import { Link, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import Navbar from "../comp/Navbar";
import api from "../servicios/api";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class ClienteCambiarPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tip_user: 2,
      dato_param: "",
      Data: [],
      error: null,
      success: null,
      cargando: null,
      error2: null,
      success2: null,
      cargando2: null,
      monto: "",
      monto2: 0,
      codigo: "",
      detalle: "",
      beneficio: "",
      Beneficios: [],
      Historial: [],
      Id_user: "",
      pass1: "",
      pass2: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cargarDatos() {
    const IdParam = this.props.match.params.id;
    this.setState({
      dato_param: IdParam,
    });
    //console.log(IdParam);
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const CargoId = cookie.get("CargoId");
    if (CargoId === "2") {
      window.location.href = "./#/home";
    }
    fetch(api + "Clientes/?consultar=" + IdParam)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            Data: datosRespuesta.data[0],
            nive_name: datosRespuesta.nivel,
            Beneficios: datosRespuesta.data[0].beneficios,
            Id_user: datosRespuesta.data[0].id,
          });
        } else {
          this.setState({
            nive_name: datosRespuesta.nivel,
            Data: [],
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const IdParam = this.props.match.params.id;
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      pass1: this.state.pass1,
      pass2: this.state.pass2,
      id_user: this.state.Id_user,
    };
    //console.log(datosEnviar);
    this.setState({
      error: null,
      success: null,
      cargando: 1,
    });
    fetch(api + "User/?cambiar_pass=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            cargando: null,
          });
        } else {
          this.setState({
            error: datosRespuesta.error,
            success: null,
            cargando: null,
          });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    const { Data, Beneficios, Historial } = this.state;
    //console.log(Data);
    function ColorTable(puntos) {
      if (puntos >= 0) {
        return "table-success";
      } else {
        return "table-danger";
      }
    }

    return (
      <div className="inicio">
        <Navbar />
        <div className="niveles">
          <br></br>
          <Link
            to={"/cliente/" + this.state.dato_param}
            className="btn btn-info"
          >
            <KeyboardArrowLeftIcon /> VOLVER
          </Link>
          <br></br>
          <div className="clientes-detalle">
            <h1> DETALLE DE CLIENTE </h1>
            <hr></hr>
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <b>NOMBRE:</b>
                    </td>
                    <td>
                      <id>{Data.name}</id>
                    </td>
                    <td>
                      <b>APELLIDO PATERNO:</b>
                    </td>
                    <td>
                      <id>{Data.ape1}</id>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>APELLIDO MATERNO:</b>
                    </td>
                    <td>
                      <id>{Data.ape2}</id>
                    </td>
                    <td>
                      <b>DNI:</b>
                    </td>
                    <td>
                      <id>{Data.dni}</id>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="ni-boxes">
            <div className="ni-subboxes">
              <form className="form" onSubmit={this.handleSubmit}>
                <h4>CAMBIAR CONTRASEÑA</h4>
                <hr></hr>
                <input
                  type="password"
                  name="pass1"
                  required
                  placeholder="CONTASEÑA"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.pass1}
                ></input>
                <br></br>
                <input
                  type="password"
                  name="pass2"
                  required
                  placeholder="VERIFICAR CONTASEÑA"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.pass2}
                ></input>
                <br></br>
                {this.state.cargando ? (
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-primary" role="alert">
                        CARGANDO
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.success ? (
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-success" role="alert">
                        CONTASEÑA CAMBIADA CORRECTAMENTE
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.error ? (
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <button className="btn btn-info">ACTUALIZAR CONTASEÑA</button>
              </form>
            </div>
            <div className="ni-subboxes"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ClienteCambiarPass);
