import React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Navbar from "../comp/Navbar";
import api from "../servicios/api";
import InfoIcon from "@mui/icons-material/Info";
import Swal from "sweetalert2";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

class Niveles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tip_user: 2,
      Data: [],
      error: null,
      success: null,
      cargando: null,
      name: "",
      visitas: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const CargoId = cookie.get("CargoId");
    if (CargoId === "2") {
      window.location.href = "./#/home";
    }
    fetch(api + "Niveles/")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            Data: datosRespuesta.data,
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      name: this.state.name,
      visitas: this.state.visitas,
    };
    //console.log(datosEnviar);

    this.setState({
      error: null,
      success: null,
      cargando: 1,
    });
    fetch(api + "Niveles/?insertar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            cargando: null,
          });
        } else {
          this.setState({
            error: datosRespuesta.error,
            success: null,
            cargando: null,
          });
        }
        this.cargarDatos();
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  BorrarRegistro = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, bórralo!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Niveles/?borrar=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "¡Eliminado!",
            "Su archivo ha sido eliminado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Tu archivo está a salvo :)",
            "error"
          );
        }
      });
  };

  render() {
    const { Data } = this.state;
    return (
      <div className="inicio">
        <Navbar />
        <div className="niveles">
          <br></br>
          <Link to="/home" className="btn btn-info">
            <KeyboardArrowLeftIcon /> VOLVER
          </Link>
          <br></br>
          <div className="ni-titulo">
            <h1>NIVELES</h1>
          </div>
          <div className="ni-boxes">
            <div className="ni-subboxes">
              <div className="table-responsive form">
                <h3>LISTA DE NIVELES</h3>
                <hr></hr>
                <table className="table">
                  <thead>
                    <tr>
                      <td>NOMBRE</td>
                      <td>VISITAS</td>
                      <td>BENEFICIOS</td>
                      <td>ELIMINAR</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>{Datos.name}</td>
                        <td>{Datos.visitas}</td>
                        <td>
                          <Link
                            to={"/beneficios/" + Datos.id}
                            className="btn btn-info"
                          >
                            {Datos.beneficios + " "}
                            <AutoFixHighIcon />
                          </Link>
                        </td>
                        <td>
                          <a
                            className="btn btn-danger"
                            onClick={() => this.BorrarRegistro(Datos.id)}
                          >
                            <DeleteIcon />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="ni-subboxes">
              <form className="form" onSubmit={this.handleSubmit}>
                <h3>AGREGAR NIVEL</h3>
                <hr></hr>
                <input
                  type="text"
                  name="name"
                  required
                  placeholder="NOMBRE DE NIVEL"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.name}
                ></input>
                <input
                  type="number"
                  name="visitas"
                  required
                  placeholder="VISITAS REQUERIDAS"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.visitas}
                ></input>
                {this.state.cargando ? (
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-primary" role="alert">
                        CARGANDO
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.success ? (
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-success" role="alert">
                        NUEVO NIVEL REGISTRADO
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.error ? (
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <button className="btn btn-success">
                  <SaveIcon /> GUARDAR
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Niveles;
