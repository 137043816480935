import React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Navbar from "../comp/Navbar";
import api from "../servicios/api";
import "../css/style.css";
import MenuCliente from "../comp/Clientes/Menu";

class MisdatosEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      cargando: null,
      pass1: "",
      pass2: "",
      Data: [],
      name: "",
      ape1: "",
      ape2: "",
      dni: "",
      phone: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  cargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    fetch(api + "User/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            Data: datosRespuesta.data[0],
            name: datosRespuesta.data[0].name,
            ape1: datosRespuesta.data[0].ape1,
            ape2: datosRespuesta.data[0].ape2,
            dni: datosRespuesta.data[0].dni,
            phone: datosRespuesta.data[0].phone,
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      id_user: IdUser,
      name: this.state.name,
      ape1: this.state.ape1,
      ape2: this.state.ape2,
      dni: this.state.dni,
      phone: this.state.phone,
    };
    //console.log(datosEnviar);
    this.setState({
      error: null,
      success: null,
      cargando: 1,
    });

    fetch(api + "User/?actualizar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            cargando: null,
          });
        } else {
          this.setState({
            error: datosRespuesta.error,
            success: null,
            cargando: null,
          });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    const { Data } = this.state;
    //console.log(Data);
    return (
      <div className="inicio">
        <Navbar />
        <div className="section">
        <MenuCliente />
          <div className="section-datos">
            <div className="cont">
              <div className="datos-edit">
                <div className="subcont">
                  <h4>CAMBIAR DATOS</h4>
                  <hr></hr>
                  <form className="form" onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col">
                        <label>Nombre:</label>
                        <input
                          type="text"
                          name="name"
                          required
                          placeholder="NOMBRE"
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.name}
                        ></input>
                      </div>
                      <div className="col">
                        <label>Apellido Paterno:</label>
                        <input
                          type="text"
                          name="ape1"
                          required
                          placeholder="APELLIDO PATERNO"
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.ape1}
                        ></input>
                      </div>
                      <div className="col">
                        <label>Apellido Materno:</label>
                        <input
                          type="text"
                          name="ape2"
                          required
                          placeholder="APELLIDO MATERNO"
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.ape2}
                        ></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>DNI:</label>
                        <input
                          type="text"
                          name="dni"
                          required
                          placeholder="DNI"
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.dni}
                        ></input>
                      </div>
                      <div className="col">
                        <label>Telefono:</label>
                        <input
                          type="text"
                          name="phone"
                          required
                          placeholder="Telefono"
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.phone}
                        ></input>
                      </div>
                      <div className="col"></div>
                    </div>

                    <br></br>
                    {this.state.cargando ? (
                      <div className="alert alert-primary" role="alert">
                        CARGANDO
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.success ? (
                      <div className="alert alert-success" role="alert">
                        DATOS CAMBIADOS CORRECTAMENTE
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.error ? (
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div>
                    ) : (
                      ""
                    )}

                    <button className="btn btn-info">ACTUALIZAR PERFIL</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MisdatosEdit;
