import React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Navbar from "../comp/Navbar";
import api from "../servicios/api";
import InfoIcon from "@mui/icons-material/Info";
import MenuCliente from "../comp/Clientes/Menu";

class Misdatos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      cargando: null,
      pass1: "",
      pass2: "",
      Data: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  cargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    fetch(api + "User/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({ Data: datosRespuesta.data[0] });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      pass1: this.state.pass1,
      pass2: this.state.pass2,
      id_user: IdUser,
    };
    //console.log(datosEnviar);
    this.setState({
      error: null,
      success: null,
      cargando: 1,
    });
    fetch(api + "User/?cambiar_pass=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            cargando: null,
          });
        } else {
          this.setState({
            error: datosRespuesta.error,
            success: null,
            cargando: null,
          });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    const { Data } = this.state;
    //console.log(Data);
    return (
      <div className="inicio">
        <Navbar />
        <div className="section">
        <MenuCliente />
          <div className="section-datos">
            <div className="cont">
              <div className="subcont0">
                <div className="subcont">
                  <h4>MIS DATOS</h4>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>
                            <b>NOMBRE:</b>
                          </td>
                          <td>
                            {Data.name + " " + Data.ape1 + " " + Data.ape2}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>DNI:</b>
                          </td>
                          <td>{Data.dni}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>TELEFONO:</b>
                          </td>
                          <td>{Data.phone}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>MI CODIGO:</b>
                          </td>
                          <td>{Data.codigo}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>CORREO:</b>
                          </td>
                          <td>{Data.email}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="subcont0">
                <div className="subcont">
                  <h4>CAMBIAR CONTRASEÑA</h4>
                  <hr></hr>
                  <form className="form" onSubmit={this.handleSubmit}>
                    <input
                      type="password"
                      name="pass1"
                      required
                      placeholder="CONTASEÑA"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.pass1}
                    ></input>
                    <br></br>
                    <input
                      type="password"
                      name="pass2"
                      required
                      placeholder="VERIFICAR CONTASEÑA"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.pass2}
                    ></input>
                    <br></br>
                    {this.state.cargando ? (
                      <div className="row">
                        <div className="col">
                          <div className="alert alert-primary" role="alert">
                            CARGANDO
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.success ? (
                      <div className="row">
                        <div className="col">
                          <div className="alert alert-success" role="alert">
                            CONTASEÑA CAMBIADA CORRECTAMENTE
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.error ? (
                      <div className="row">
                        <div className="col">
                          <div className="alert alert-danger" role="alert">
                            {this.state.error}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <button className="btn btn-info">
                      ACTUALIZAR CONTASEÑA
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Misdatos;
