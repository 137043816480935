import React from "react";
import "../../css/estiloWa.css";

export default function Redes() {
    return (

        <div class="shared-wa">
            <a target="_blank" className="wa" title="Whatsapp" href="https://wa.me/51916813481"><img src="img/redes/whatsapp.png" class="imgwa" alt="whatsapp logo coulant"></img></a>
        </div>
    );
}