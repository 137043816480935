import { Routes, Route, HashRouter } from "react-router-dom";
import Beneficios from "./view/Beneficios";
import Home from "./view/home";
import Login from "./view/Login";
import Misdatos from "./view/Misdatos";
import Niveles from "./view/Niveles";
import Clientes from "./view/Clientes";
import Registro from "./view/Registro";
import Cliente from "./view/Cliente";
import Cargar from "./view/Cargar";
import ClienteCambiarPass from "./view/ClienteCambiarPass";
import MisdatosEdit from "./view/MisdatosEdit";
import RecuperarPass from "./view/RecuperarPass";
import Restablecer from "./view/Restablecer";
import Tarjeta3D from "./view/Tarjeta3D";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Login />} />
        <Route path="/registro" element={<Registro />} />
        <Route path="/home" element={<Home />} />
        <Route path="/mis-datos" element={<Misdatos />} />
        <Route path="/mis-datos-editar" element={<MisdatosEdit />} />
        <Route path="/niveles" element={<Niveles />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/beneficios/:id" element={<Beneficios />} />
        <Route path="/cliente/:id" element={<Cliente />} />
        <Route path="/cargar" element={<Cargar />} />
        <Route path="/cliente-cambiar-pass/:id" element={<ClienteCambiarPass />} />
        <Route path="/recuperar-pass" element={<RecuperarPass />} />
        <Route path="/restablecer/:id" element={<Restablecer />} />
        <Route path="/Tajeta3d/:id" element={<Tarjeta3D />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
