import React, { Suspense, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import Navbar from "../comp/Navbar";
import api from "../servicios/api";
import "../css/style.css";
import { Canvas } from "react-three-fiber";
import { OrbitControls } from "@react-three/drei";
import { TarjetaClasica } from "../comp/TarjetaClasica";
import { TarjetaPlata } from "../comp/TarjetaPlata";
import { TarjetaDorada } from "../comp/TarjetaDorada";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class Tarjeta3D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dato_param: 0,
    };
  }

  cargarDatos() {
    const IdParam = this.props.match.params.id;
    this.setState({
      dato_param: IdParam,
    });
    //console.log(IdParam);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    const { dato_param } = this.state;
    console.log(dato_param);

    return (
      <div className="inicio">
        <Navbar />
        <div className="section">
          <Link to="/home" className="btn btn-info">
            VOLVER
          </Link>
          <div className="inicio-section">
            <div className="cont">
              <div className="canvas">
                <Canvas camera={{ zoom: 6, position: [-17, 5, 10] }}>
                  <ambientLight intensity={0.5} />
                  <pointLight position={[35, 35, 0]} intensity={0.4} />
                  <pointLight position={[-35, 35, 0]} intensity={0.4} />
                  <Suspense fallback={null}>
                    {dato_param === "1" ? <TarjetaClasica /> : ""}
                    {dato_param === "2" ? <TarjetaPlata /> : ""}
                    {dato_param === "3" ? <TarjetaDorada /> : ""}
                  </Suspense>
                  <OrbitControls />
                </Canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Tarjeta3D);
