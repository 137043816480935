import React from "react";
import "../css/login.css";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import api from "../servicios/api";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

class RecuperarPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      cargando: null,
      email: "",
      pass1: "",
      showPass: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  BtnShowPass() {
    if (this.state.showPass === 1) {
      //console.log("Contraseña Ocultada");
      this.setState({
        showPass: null,
      });
    } else {
      //console.log("Contraseña Mostrada");
      this.setState({
        showPass: 1,
      });
    }
  }

  cargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    /*Valida sesion*/
    if (IdUser) {
      //Sesion existe
      window.location.href = "./#/home";
    }
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      email: this.state.email,
      asunto: "RECUPERACION DE CUENTA",
      mensaje: "RecuperarPass",
      d_titulo: "COULANT",
    };
    //console.log(datosEnviar);
    this.setState({
      error: null,
      success: null,
      cargando: 1,
    });

    fetch(api + "Mail/?enviar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            cargando: null,
          });
        } else {
          this.setState({
            error: datosRespuesta.error,
            success: null,
            cargando: null,
          });
        }
        console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    return (
      <div className="login">
        <div className="cont-login">
          <div className="img-login">
            <img src="img/logo-couland.png" alt="Logo de Coulant"></img>
          </div>
          <br></br>
          <br></br>
          <div className="login-form">
            <div className="alert alert-info" role="alert">
              <h3>Recuperar contraseña</h3>
              <hr></hr>
              Para recuperar su Contraseña escriba su correo electronico.
              Nuestro sistema le enviara un correo de verificación para que
              pueda cambiar su contraseña.
            </div>
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="input-wrapper">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  required
                  placeholder="Usuario"
                  onChange={this.handleChange}
                  value={this.state.email}
                ></input>
                <PersonIcon className="input-icon" />
              </div>
              {this.state.cargando ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-primary" role="alert">
                      ENVIANDO
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.success ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-success" role="alert">
                      SE LE ENVIO UN CORREO ELECTRÓNICO PARA PODER RECUPERAR SU CONTRASEÑA.
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.error ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-danger" role="alert">
                      {this.state.error}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="login-boton">
                <button className="btn btn-primary">Recuperar mi contraseña</button>
              </div>
              <hr></hr>

              <div className="login-register">
                <Link to="/">Volver a Inicio de Sesión</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default RecuperarPass;
