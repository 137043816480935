import React, { useEffect, useState, useRef } from "react";
import api from "../servicios/api";
import Cookies from "universal-cookie";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PaymentsIcon from "@mui/icons-material/Payments";
import "../css/style.css";
import Swal from "sweetalert2";
import Redes from "./Clientes/Redes";

export default function navbar() {
  const cookie = new Cookies();
  const UserSaldo = cookie.get("UserSaldo");
  const UserPunto = cookie.get("UserPunto");
  const CargoId = cookie.get("CargoId");

  function showCerrarSesion() {
    Swal.fire({
      title: "¿Estás seguro de que quieres cerrar la sesión?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //Cerrar Sesion
        cookie.remove("UserEmail", { path: "/" });
        cookie.remove("UserPassw", { path: "/" });
        cookie.remove("UserName", { path: "/" });
        cookie.remove("CargoId", { path: "/" });
        cookie.remove("UserId", { path: "/" });
        cookie.remove("UserSaldo", { path: "/" });
        cookie.remove("UserPunto", { path: "/" });
        window.location.href = "./#/";
        //Swal.fire("Saved!", "sdss", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  function ValidarSesion() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserEmail");
    if (IdUser) {
      //console.log("existe");
    } else {
      window.location.href = "./#/";
      //console.log("no existe ");
    }
  }
  ValidarSesion();

  //console.log(CargoId);
  if (CargoId === "1") {
    return (
      <navbar>
        <div className="navbar">
          <img src="img/logo-couland.png"></img>
          <div className="">
            <ul className="">
              <li className="">
                <a onClick={showCerrarSesion}>
                  <LogoutIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </navbar>
    );
  } else {
    return (
      <navbar>
        <div className="navbar">
          <img src="img/logo-couland.png"></img>
          <div className="">
            <ul className="">
              <li className="">
                <b>
                  <PaymentsIcon />
                </b>
                <i>s/{UserSaldo}</i>
              </li>
              <li className="">
                <b>
                  <AccountBalanceWalletIcon />
                </b>
                <i>{UserPunto}</i>
              </li>
              <li className="">
                <a onClick={showCerrarSesion}>
                  <LogoutIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </navbar>
    );  
  }
}
