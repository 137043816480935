import React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Navbar from "../comp/Navbar";
import api from "../servicios/api";
import InfoIcon from "@mui/icons-material/Info";
import Swal from "sweetalert2";
import SearchIcon from "@mui/icons-material/Search";
import GroupIcon from "@mui/icons-material/Group";
import LayersIcon from "@mui/icons-material/Layers";
import ArticleIcon from "@mui/icons-material/Article";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import "../css/style.css";

import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import MenuCliente from "../comp/Clientes/Menu";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      Nivel: [],
      Nivel_s: [],
      num_visitas: 0,
      bene: [],
      num_bene: 0,
      tarjeta: 1,
      tip_user: 2,
      dni: "",
      total_clientes: 0,
      total_saldo: 0,
      total_puntos: 0,
      nuevos_clientes: 0,
      nivel1_name: "",
      nivel1_bene: [],
      nivel2_name: "",
      nivel2_bene: [],
      nivel3_name: "",
      nivel3_bene: [],

      Chart_new_cli: [],
      bono: null,
      Data_bono: [],
      bono_re: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  cargarResumen() {
    fetch(api + "Reportes/?resumen=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          total_clientes: datosRespuesta.total_clientes,
          total_saldo: datosRespuesta.total_saldo,
          total_puntos: datosRespuesta.total_puntos,
          nuevos_clientes: datosRespuesta.nuevos_clientes,
        });
      })
      .catch(console.log);
  }

  cargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const CargoId = cookie.get("CargoId");
    this.setState({
      tip_user: CargoId,
    });

    fetch(api + "User/?consulta=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            Data: datosRespuesta.data[0],
            Nivel: datosRespuesta.nivel,
            Nivel_s: datosRespuesta.nivel_si,
            num_visitas: datosRespuesta.num_visitas,
            bene: datosRespuesta.beneficios,
            num_bene: datosRespuesta.num_beneficios,
            tarjeta: datosRespuesta.nivel.id,
          });
        }
      })
      .catch(console.log);
  }

  DetalleVisitas() {
    Swal.fire(
      "",
      "El número de visitas se calcula sumando la cantidad de días que nos visitó en rango desde hace un año hasta hoy.",
      "question"
    );
  }
  DetalleBeneficios() {
    const { num_bene, bene } = this.state;
    var detalle =
      '<table class="table"><tr><td>BENEFICIO</td><td>PUNTOS</td></tr>';
    for (var i = 0; i < num_bene; i++) {
      detalle =
        detalle +
        "<tr><td>" +
        bene[i].descrip +
        "</td><td>" +
        Math.round(bene[i].num_puntos) +
        "</td></tr>";
    }
    detalle = detalle + "</table>";
    Swal.fire({
      title: "<strong>MIS BENEFICIOS</strong>",
      icon: "info",
      html: detalle,
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: "PERFECTO",
      confirmButtonAriaLabel: "Thumbs up, great!",
      cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: "Thumbs down",
    });
  }

  DetalleCodigo() {
    Swal.fire(
      "",
      "Código promocional ilimitado para compartir con otros usuarios, el código otorgará un 5% de descuento en cualquier producto, y por cada compra de un referido se acumulará 10% de puntos de la compra al dueño del código.  Este mismo entra en función una vez canjeada 5 puntos hasta vencer la tarjeta.",
      "question"
    );
  }

  cargarNiveles1() {
    fetch(api + "Beneficios/?nivel=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            nivel1_bene: datosRespuesta.data,
            nivel1_name: datosRespuesta.nivel,
          });
        } else {
          this.setState({
            nivel1_name: datosRespuesta.nivel,
            nivel1_bene: [],
          });
        }
      })
      .catch(console.log);
  }

  cargarNiveles2() {
    fetch(api + "Beneficios/?nivel=2")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            nivel2_bene: datosRespuesta.data,
            nivel2_name: datosRespuesta.nivel,
          });
        } else {
          this.setState({
            nivel2_name: datosRespuesta.nivel,
            nivel2_bene: [],
          });
        }
      })
      .catch(console.log);
  }

  cargarNiveles3() {
    fetch(api + "Beneficios/?nivel=3")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            nivel3_bene: datosRespuesta.data,
            nivel3_name: datosRespuesta.nivel,
          });
        } else {
          this.setState({
            nivel3_name: datosRespuesta.nivel,
            nivel3_bene: [],
          });
        }
      })
      .catch(console.log);
  }
  cargarNiveles4() {
    fetch(api + "Reportes/charts.php?nuevos_clientes=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);

        if (datosRespuesta.success === true) {
          this.setState({
            Chart_new_cli: datosRespuesta.data,
          });
        } else {
          this.setState({
            Chart_new_cli: [],
          });
        }
      })
      .catch(console.log);
  }

  cargarBono() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      id_user: IdUser,
    };

    fetch(api + "Bono/?consulta_bono=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            bono: null,
            Data_bono: [],
          });
          this.cargarBono();
        } else {
          this.setState({
            bono: 1,
            Data_bono: datosRespuesta.bono,
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarResumen();
    this.cargarNiveles1();
    this.cargarNiveles2();
    this.cargarNiveles3();
    this.cargarNiveles4();
    this.cargarBono();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  ReclamarBono(id_bono) {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      id_user: IdUser,
      id_bono: id_bono,
    };

    fetch(api + "Bono/?insertar_bono=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            bono_re: 1,
          });
          this.cargarBono();
          this.RecargarDatos();
        } else {
          this.setState({
            bono_re: null,
          });
        }
      })
      .catch(console.log);
  }

  RecargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    fetch(api + "User/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          const cookie = new Cookies();
          var cookieexpires = new Date(Date.now() + 864000000000);

          cookie.set("UserPunto", datosRespuesta.data[0].puntos, {
            path: "/",
            expires: cookieexpires,
          });
          cookie.set("UserSaldo", datosRespuesta.data[0].saldo, {
            path: "/",
            expires: cookieexpires,
          });
        }
      })
      .catch(console.log);
  }

  DetalleBeneficios(id_beneficio) {
    fetch(api + "Beneficios/?consultar=" + id_beneficio)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        Swal.fire(
          datosRespuesta.data[0].descrip,
          datosRespuesta.data[0].terminos,
          "question"
        );
      })
      .catch(console.log);
  }

  render() {
    const {
      Data,
      nivel1_bene,
      nivel2_bene,
      nivel3_bene,
      Chart_new_cli,
      Data_bono,
    } = this.state;
    //console.log(this.state.dni);

    const porcentaje =
      (this.state.num_visitas * 100) / this.state.Nivel_s.visitas;
    var style_targeta = "tarjeta";
    if (this.state.tarjeta === "1") {
      style_targeta = "tarjeta";
    } else if (this.state.tarjeta === "2") {
      style_targeta = "tarjeta permium";
    } else {
      style_targeta = "tarjeta deluxe";
    }

    if (this.state.tip_user === "1") {
      const userTable = {
        labels: Chart_new_cli.map((data) => data.d_resume),
        datasets: [
          {
            label: "Nuevos Clientes",
            data: Chart_new_cli.map((data) => data.count),
            backgroundColor: [
              "rgba(75,192,192,1)",
              "#ecf0f1",
              "#50AF95",
              "#f3ba2f",
              "#2a71d0",
            ],
            borderColor: "black",
            borderWidth: 2,
          },
        ],
      };

      return (
        <div className="inicio">
          <Navbar />
          <div className="index-section">
            <h4>ADMINISTRADOR</h4>
            <hr></hr>
            <div className="buscador">
              <form className="form ">
                <input
                  type="number"
                  name="dni"
                  placeholder="BUSCAR POR DNI"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.dni}
                ></input>
              </form>
              <Link
                to={"/cliente/" + this.state.dni}
                className="btn btn-primary"
              >
                <SearchIcon />
              </Link>
            </div>
            <div className="boxes">
              <div className="subboxes">
                <Link to={"/clientes"}>
                  <h3>
                    <GroupIcon />
                  </h3>
                  <p>
                    <i>CLIENTES</i>
                  </p>
                </Link>
              </div>
              <div className="subboxes">
                <Link to={"/cargar"}>
                  <h3>
                    <ArticleIcon />
                  </h3>
                  <p>
                    <i>CARGAR</i>
                  </p>
                </Link>
              </div>
              <div className="subboxes">
                <Link to={"/niveles"}>
                  <h3>
                    <LayersIcon />
                  </h3>
                  <p>
                    <i>NIVELES</i>
                  </p>
                </Link>
              </div>
              <div className="subboxes">
                <Link to={"/"}>
                  <h3>
                    <ShowChartIcon />
                  </h3>
                  <p>
                    <i>REPORTES</i>
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="row resumen">
            <div className="col">
              <div className="chard">
                <Bar data={userTable} />
              </div>
            </div>
            <div className="col">
              <div className="table-responsive cl-boxes">
                <h3>RESUMEN</h3>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>NUEVOS CLIENTES</td>
                      <td>TOTAL CLIENTES</td>
                      <td>TOTAL PUNTO</td>
                      <td>TOTAL SALDO</td>
                    </tr>
                    <tr>
                      <td>{this.state.nuevos_clientes}</td>
                      <td>{this.state.total_clientes}</td>
                      <td>{this.state.total_puntos}</td>
                      <td>{this.state.total_saldo}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="inicio">
          <Navbar />
          <div className="section">
           <MenuCliente />
            <div className="inicio-section">
              <div className="cont">
                <div className="subcont0">
                  <div className="subcont">
                    <h2>MIS VISITAS</h2>
                    <hr></hr>
                    <div>
                      {this.state.bono ? (
                        <div className="alert alert-info" role="alert">
                          {Data_bono.descrip}{" "}
                          <a
                            className="btn btn-success"
                            onClick={() => this.ReclamarBono(Data_bono.id)}
                          >
                            RECLAMAR BONO
                          </a>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.bono_re ? (
                        <div className="alert alert-success" role="alert">
                          BONO RECLAMADO CORRECTAMENTE
                        </div>
                      ) : (
                        ""
                      )}
                      <h4>{this.state.num_visitas} visitas</h4>
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-striped bg-info"
                          role="progressbar"
                          style={{
                            width: porcentaje + "%",
                          }}
                          aria-valuenow={porcentaje}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                      <i>
                        +{this.state.Nivel_s.visitas - this.state.num_visitas}{" "}
                        visitas seras cliente {this.state.Nivel_s.name}.{" "}
                        <a
                          className="boton-info"
                          onClick={() => this.DetalleVisitas()}
                        >
                          <InfoIcon />
                        </a>
                      </i>
                    </div>
                  </div>
                </div>
                <div className="subcont0">
                  <div className="subcont">
                    <h2>MI NIVEL - <Link to={"/Tajeta3d/"+this.state.tarjeta} className="btn btn-warning"> <ThreeDRotationIcon /></Link></h2>
                    
                    <hr></hr>
                    <div className={style_targeta}>
                      <h4 className="title-socio">SOCIO COULANT</h4> 
                      
                      <br></br>
                      <br></br>
                      <br></br>
                      <div className="box">
                        <div className="subbox">
                          <div className="contbox">
                            <h4>{Data.puntos}</h4>
                            <p>
                              <i>MIS PUNTOS</i>
                            </p>
                          </div>
                        </div>
                        <div className="subbox">
                          <h4>s/{Data.saldo}</h4>
                          <p>
                            <i>MI SALDO</i>
                          </p>
                        </div>
                        <div className="subbox">
                          <h4>{this.state.num_bene}</h4>
                          <p>
                            <i>MIS BENEFICIOS</i>
                            <a
                              className="boton-info2"
                              onClick={() => this.DetalleBeneficios()}
                            >
                              <InfoIcon />
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="data-user">
                        <div className="data-user-box">
                          <p>{Data.name + " " + Data.ape1}</p>
                        </div>
                        <div className="data-user-box">
                          <p>
                            COD REFERIDO: <i>{Data.codigo}</i>
                            <a
                              className="boton-info2"
                              onClick={() => this.DetalleCodigo()}
                            >
                              <InfoIcon />
                            </a>
                          </p>
                        </div>
                      </div>
                      <h4 className="title-socio">
                        <b>
                          SOCIO: <i>{this.state.Nivel.name}</i>
                        </b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont ">
                <h2>LISTA DE BENEFICIOS</h2>
                <br></br>
                <div className="row">
                  <div className="col ">
                    <div className="niveles-cont card text-white bg-light mb-3">
                      <h3>{this.state.nivel1_name} <Link to={"/Tajeta3d/1"} className="btn btn-info"> <ThreeDRotationIcon /></Link></h3>
                      <img
                        src="/img/Tarjetas/socioclasic-min.png"
                        alt="tarjeta"
                      ></img>
                      <div className="table-responsive form">
                        <table className="table">
                          <thead>
                            <tr>
                              <td>DETALLE</td>
                              <td>PUNTOS</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            {nivel1_bene.map((Datos) => (
                              <tr className="text-center" key={Datos.id}>
                                <td>{Datos.descrip}</td>
                                <td>{Math.round(Datos.num_puntos)}</td>
                                <td>
                                  <a
                                    className="boton-info"
                                    onClick={() =>
                                      this.DetalleBeneficios(Datos.id)
                                    }
                                  >
                                    <InfoIcon />
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="niveles-cont card text-white bg-warning mb-3">
                      <h3>{this.state.nivel2_name} <Link to={"/Tajeta3d/2"} className="btn btn-info"> <ThreeDRotationIcon /></Link></h3>
                      <img
                        src="/img/Tarjetas/sociopremiun-min.png"
                        alt="tarjeta"
                      ></img>
                      <div className="table-responsive form">
                        <table className="table">
                          <thead>
                            <tr>
                              <td>DETALLE</td>
                              <td>PUNTOS</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            {nivel2_bene.map((Datos) => (
                              <tr className="text-center" key={Datos.id}>
                                <td>{Datos.descrip}</td>
                                <td>{Math.round(Datos.num_puntos)}</td>
                                <td>
                                  <a
                                    className="boton-info"
                                    onClick={() =>
                                      this.DetalleBeneficios(Datos.id)
                                    }
                                  >
                                    <InfoIcon />
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="niveles-cont card text-white bg-dark mb-3">
                      <h3>{this.state.nivel3_name} <Link to={"/Tajeta3d/3"} className="btn btn-info"> <ThreeDRotationIcon /></Link></h3>
                      <img
                        src="/img/Tarjetas/sociodelux-min.png"
                        alt="tarjeta"
                      ></img>
                      <div className="table-responsive form">
                        <table className="table">
                          <thead>
                            <tr>
                              <td>DETALLE</td>
                              <td>PUNTOS</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            {nivel3_bene.map((Datos) => (
                              <tr className="text-center" key={Datos.id}>
                                <td>{Datos.descrip}</td>
                                <td>{Math.round(Datos.num_puntos)}</td>
                                <td>
                                  <a
                                    className="boton-info"
                                    onClick={() =>
                                      this.DetalleBeneficios(Datos.id)
                                    }
                                  >
                                    <InfoIcon />
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Home;
