import React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import api from "../servicios/api";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import "../css/registro.css";
class Registro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      cargando: null,
      name: "",
      ape1: "",
      ape2: "",
      dni: "",
      phone: "",
      codigo: "",
      email: "",
      pass1: "",
      pass2: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cargarDatos() {}

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      name: this.state.name,
      ape1: this.state.ape1,
      ape2: this.state.ape2,
      dni: this.state.dni,
      phone: this.state.phone,
      codigo: this.state.codigo,
      email: this.state.email,
      pass1: this.state.pass1,
      pass2: this.state.pass2,
    };
    console.log(datosEnviar);
    this.setState({
      error: null,
      success: null,
      cargando: 1,
    });
    fetch(api + "User/?insertar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            cargando: null,
          });
        } else {
          this.setState({
            error: datosRespuesta.error,
            success: null,
            cargando: null,
          });
        }
        console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    return (
      <div className="registro">
        <Link to="/" className="volver">
          <KeyboardArrowLeftIcon />{" "}
        </Link>
        <h1>REGISTRO</h1>
        <hr></hr>
        <form className="form" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col">
              <input
                type="text"
                name="name"
                required
                placeholder="Nombre"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.name}
              ></input>
            </div>
            <div className="col">
              <input
                type="text"
                name="ape1"
                required
                placeholder="Apellido Paterno"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.ape1}
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <input
                type="text"
                name="ape2"
                required
                placeholder="Apellido materno"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.ape2}
              ></input>
            </div>
            <div className="col">
              <input
                type="text"
                name="phone"
                required
                placeholder="Telefono"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.phone}
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <input
                type="text"
                name="dni"
                required
                placeholder="DNI"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.dni}
              ></input>
            </div>
            <div className="col">
              <input
                type="text"
                name="codigo"
                placeholder="Codigo de referido"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.codigo}
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <input
                type="email"
                name="email"
                required
                placeholder="Correo Electrónico"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.email}
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <input
                type="password"
                name="pass1"
                required
                placeholder="Contraseña"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.pass1}
              ></input>
            </div>
            <div className="col">
              <input
                type="password"
                name="pass2"
                placeholder="Verificar Contraseña"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.pass2}
              ></input>
            </div>
          </div>
          {this.state.cargando ? (
            <div className="row">
              <div className="col">
                <div className="alert alert-primary" role="alert">
                  CARGANDO
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.success ? (
            <div className="row">
              <div className="col">
                <div className="alert alert-success" role="alert">
                  CUENTA CREADA CORRECTAMENTE{" "}
                  <Link to="/" className="btn btn-info">
                    INICIAR SESIÓN
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.error ? (
            <div className="row">
              <div className="col">
                <div className="alert alert-danger" role="alert">
                  {this.state.error}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <div className="col">
              <button className="btn btn-primay">REGISTRARME</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Registro;
