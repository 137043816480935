import React from "react";
import { Link, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import Navbar from "../comp/Navbar";
import api from "../servicios/api";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import "../css/style.css";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class Cliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tip_user: 2,
      dato_param: "",
      Data: [],
      error: null,
      success: null,
      cargando: null,
      error2: null,
      success2: null,
      cargando2: null,
      error3: null,
      success3: null,
      cargando4: null,
      monto: "",
      monto2: 0,
      monto3: "",
      codigo: "",
      detalle: "",
      detalle_a: "SE AGREGO SALDO",
      detalle_r: "SE RETIRO SALDO",
      beneficio: "",
      Beneficios: [],
      Historial: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleSubmit3 = this.handleSubmit3.bind(this);
  }

  cargarDatos() {
    const IdParam = this.props.match.params.id;
    this.setState({
      dato_param: IdParam,
    });
    //console.log(IdParam);
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const CargoId = cookie.get("CargoId");
    if (CargoId === "2") {
      window.location.href = "./#/home";
    }
    fetch(api + "Clientes/?consultar=" + IdParam)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            Data: datosRespuesta.data[0],
            nive_name: datosRespuesta.nivel,
            Beneficios: datosRespuesta.data[0].beneficios,
          });
        } else {
          this.setState({
            nive_name: datosRespuesta.nivel,
            Data: [],
          });
        }
      })
      .catch(console.log);
  }
  cargarHistorial() {
    const IdParam = this.props.match.params.id;

    fetch(api + "Puntos/?usuario=" + IdParam)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            Historial: datosRespuesta.data,
          });
        } else {
          this.setState({
            Historial: [],
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarHistorial();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const IdParam = this.props.match.params.id;
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      monto: this.state.monto,
      detalle: this.state.detalle_a,
      id_user: IdParam,
    };
    //console.log(datosEnviar);

    this.setState({
      error: null,
      success: null,
      cargando: 1,
    });
    fetch(api + "Saldo/?insertar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            cargando: null,
          });
        } else {
          this.setState({
            error: datosRespuesta.error,
            success: null,
            cargando: null,
          });
        }
        this.cargarDatos();
        this.cargarHistorial();
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  handleSubmit2(event) {
    const IdParam = this.props.match.params.id;
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      beneficio: this.state.beneficio,
      monto: this.state.monto2,
      id_user: IdParam,
    };
    //console.log(datosEnviar);

    this.setState({
      error2: null,
      success2: null,
      cargando2: 1,
    });
    fetch(api + "Puntos/?canjear=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success2: 1,
            error2: null,
            cargando2: null,
          });
        } else {
          this.setState({
            error2: datosRespuesta.error,
            success2: null,
            cargando2: null,
          });
        }
        this.cargarDatos();
        this.cargarHistorial();
      })
      .catch(console.log);
    event.preventDefault();
  }

  handleSubmit3(event) {
    const IdParam = this.props.match.params.id;
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      monto: this.state.monto3,
      detalle: this.state.detalle_r,
      id_user: IdParam,
    };
    //console.log(datosEnviar);

    this.setState({
      error3: null,
      success3: null,
      cargando3: 1,
    });
    fetch(api + "Saldo/?retirar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success3: 1,
            error3: null,
            cargando3: null,
          });
        } else {
          this.setState({
            error3: datosRespuesta.error,
            success3: null,
            cargando3: null,
          });
        }
        this.cargarDatos();
        this.cargarHistorial();
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  render() {
    const { Data, Beneficios, Historial } = this.state;
    //console.log(Data);
    function ColorTable(puntos) {
      if (puntos >= 0) {
        return "table-success";
      } else {
        return "table-danger";
      }
    }

    return (
      <div className="inicio">
        <Navbar />
        <div className="niveles">
          <br></br>
          <Link to="/clientes" className="btn btn-info">
            <KeyboardArrowLeftIcon /> VOLVER
          </Link>
          <br></br>
          <div className="clientes-detalle">
            <h1> DETALLE DE CLIENTE </h1>
            <hr></hr>
            <div className="table-responsive">
              <div className="row">
                <div className="col">
                  <Link
                    to={"/cliente-cambiar-pass/" + this.state.dato_param}
                    className="btn btn-info"
                  >
                    Cambiar contraseña
                  </Link>
                </div>
                <div className="col">
                  <a className="btn btn-danger">VINCULAR TARJETA</a>
                </div>
              </div>
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <b>NOMBRE:</b>
                    </td>
                    <td>
                      <id>{Data.name}</id>
                    </td>
                    <td>
                      <b>APELLIDO PATERNO:</b>
                    </td>
                    <td>
                      <id>{Data.ape1}</id>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>APELLIDO MATERNO:</b>
                    </td>
                    <td>
                      <id>{Data.ape2}</id>
                    </td>
                    <td>
                      <b>DNI:</b>
                    </td>
                    <td>
                      <id>{Data.dni}</id>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>TELEFONO:</b>
                    </td>
                    <td>
                      <id>{Data.phone}</id>
                    </td>
                    <td>
                      <b>CODIGO DE AFILIADO:</b>
                    </td>
                    <td>
                      <id>{Data.codigo}</id>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>CORREO ELECTRÓNICO:</b>
                    </td>
                    <td>
                      <id>{Data.email}</id>
                    </td>
                    <td>
                      <b>NIVEL:</b>
                    </td>
                    <td>
                      <id>{Data.nivel}</id>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>VISITAS:</b>
                    </td>
                    <td>
                      <id>{Data.vistas}</id>
                    </td>
                    <td>
                      <b>REFERIDOS:</b>
                    </td>
                    <td>
                      <id>{Data.referidos}</id>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>PUNTOS:</b>
                    </td>
                    <td>
                      <id>{Data.puntos}</id>
                    </td>

                    <td>
                      <b>SALDO:</b>
                    </td>
                    <td>
                      <id>{Data.saldo}</id>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>CODIGO TARJETA:</b>
                    </td>
                    <td></td>
                    <td>
                      <b>COD BARRAS:</b>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="ni-boxes">
            <div className="row">
              <div className="col">
                <form className="form" onSubmit={this.handleSubmit}>
                  <h3>AGREGAR SALDO</h3>
                  <hr></hr>
                  <input
                    type="number"
                    name="monto"
                    required
                    placeholder="MONTO A AGREGAR"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.monto}
                  ></input>
                  <br></br>
                  <input
                    type="text"
                    name="detalle_a"
                    required
                    placeholder="DETALLE"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.detalle_a}
                  ></input>
                  {this.state.cargando ? (
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-primary" role="alert">
                          CARGANDO
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.success ? (
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-success" role="alert">
                          OPERACIÓN CORRECTA
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.error ? (
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-danger" role="alert">
                          {this.state.error}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <button className="btn btn-success">AGREGAR</button>
                </form>
              </div>
              <div className="col">
                <form className="form" onSubmit={this.handleSubmit3}>
                  <h3>RETIRAR SALDO</h3>
                  <hr></hr>
                  <input
                    type="number"
                    name="monto3"
                    required
                    placeholder="MONTO A RETIRAR"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.monto3}
                  ></input>
                  <br></br>
                  <input
                    type="text"
                    name="detalle_r"
                    required
                    placeholder="DETALLE"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.detalle_r}
                  ></input>
                  {this.state.cargando3 ? (
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-primary" role="alert">
                          CARGANDO
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.success3 ? (
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-success" role="alert">
                          OPERACIÓN CORRECTA
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.error3 ? (
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-danger" role="alert">
                          {this.state.error3}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <button className="btn btn-danger">RETIRAR</button>
                </form>
              </div>
              <div className="col">
                <form className="form" onSubmit={this.handleSubmit2}>
                  <h3>CANJEAR</h3>
                  <hr></hr>
                  <select
                    className="form-control"
                    name="beneficio"
                    required
                    onChange={this.handleChange}
                  >
                    <option></option>
                    {Beneficios.map((Datos) => (
                      <option key={Datos.id} value={Datos.id}>
                        {Datos.descrip} ({Datos.num_puntos + " puntos"})
                      </option>
                    ))}
                  </select>
                  <label>SALDO A CANJEAR:</label>
                  <input
                    type="number"
                    name="monto2"
                    required
                    placeholder="SALDO A CANJEAR"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.monto2}
                  ></input>
                  {this.state.cargando2 ? (
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-primary" role="alert">
                          CARGANDO
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.success2 ? (
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-success" role="alert">
                          OPERACIÓN CORRECTA
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.error2 ? (
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-danger" role="alert">
                          {this.state.error2}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <button className="btn btn-info">CANJEAR</button>
                </form>
              </div>
            </div>
          </div>
          <div className="clientes-detalle">
            <h3>HISTORIAL DE PUNTOS</h3>
            <hr></hr>
            <div className="table-responsive">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <td>FECHA</td>
                    <td>DETALLE</td>
                    <td>PUNTOS</td>
                    <td>SALDO</td>
                  </tr>
                </thead>
                <tbody>
                  {Historial.map((Datos) => (
                    <tr
                      className={"text-center " + ColorTable(Datos.puntos)}
                      key={Datos.id}
                    >
                      <td className={ColorTable(Datos.puntos)}>
                        {Datos.fecha}
                      </td>
                      <td className={ColorTable(Datos.puntos)}>
                        {Datos.detalle}
                      </td>
                      <td className={ColorTable(Datos.puntos)}>
                        {Datos.puntos}
                      </td>
                      <td className={ColorTable(Datos.puntos)}>
                        {Datos.saldo}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Cliente);
