import React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Navbar from "../comp/Navbar";
import api from "../servicios/api";
import InfoIcon from "@mui/icons-material/Info";
import Swal from "sweetalert2";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

class Clientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tip_user: 2,
      Data: [],
      tablaBusqueda: [],
      busqueda: "",
      error: null,
      success: null,
      cargando: null,
      name: "",
      visitas: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const CargoId = cookie.get("CargoId");
    if (CargoId === "2") {
      window.location.href = "./#/home";
    }
    fetch(api + "Clientes/")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            Data: datosRespuesta.data,
            tablaBusqueda: datosRespuesta.data,
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
    this.filtrar(target.value);
  }

  handleSubmit(event) {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");

    event.preventDefault();
  }

  filtrar(termino) {
    var resultadoBusqueda = this.state.tablaBusqueda.filter((elemento) => {
      if (
        elemento.dni.toString().toLowerCase().includes(termino.toLowerCase()) ||
        elemento.ape1
          .toString()
          .toLowerCase()
          .includes(termino.toLowerCase()) ||
        elemento.ape2
          .toString()
          .toLowerCase()
          .includes(termino.toLowerCase()) ||
        elemento.name.toString().toLowerCase().includes(termino.toLowerCase())
      ) {
        return elemento;
      }
    });
    this.setState({
      Data: resultadoBusqueda,
    });
  }

  render() {
    const { Data } = this.state;
    return (
      <div className="inicio">
        <Navbar />
        <div className="niveles">
          <br></br>
          <Link to="/home" className="btn btn-info">
            <KeyboardArrowLeftIcon /> VOLVER
          </Link>
          <br></br>
          <div className="cl-boxes">
            <h1>LISTA DE CLIENTES</h1>
            <hr></hr>
            <div className="table-responsive">
              <div>
                <form onSubmit={this.handleSubmit} className="containerInput">
                  <input
                    type="search"
                    placeholder="Nombre, Apellidos o DNI."
                    className="form-control inputBuscar"
                    name="busqueda"
                    onChange={this.handleChange}
                    value={this.state.busqueda}
                  ></input>
                </form>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <td>VER</td>
                    <td>NOMBRE</td>
                    <td>DNI</td>
                    <td>PUNTOS</td>
                    <td>SALDO</td>
                    <td>VISITAS</td>
                    <td>NIVEL</td>
                    <td>REFERIDOS</td>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((Datos) => (
                    <tr className="text-center" key={Datos.id}>
                      <td>
                        <Link
                          to={"/cliente/" + Datos.dni}
                          className="btn btn-info"
                        >
                          <RemoveRedEyeIcon />
                        </Link>
                      </td>
                      <td>
                        {Datos.name + " " + Datos.ape1 + " " + Datos.ape2}
                      </td>
                      <td>{Datos.dni}</td>
                      <td>{Datos.puntos}</td>
                      <td>s/{Datos.saldo}</td>
                      <td>{Datos.vistas}</td>
                      <td>{Datos.nivel}</td>
                      <td>{Datos.referidos}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Clientes;
