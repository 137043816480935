import React from "react";
import "../css/login.css";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { Link, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import api from "../servicios/api";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class Restablecer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      cargando: null,
      email: "",
      pass1: "",
      pass2: "",
      showPass: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  BtnShowPass() {
    if (this.state.showPass === 1) {
      //console.log("Contraseña Ocultada");
      this.setState({
        showPass: null,
      });
    } else {
      //console.log("Contraseña Mostrada");
      this.setState({
        showPass: 1,
      });
    }
  }

  componentDidMount() {}

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const IdParam = this.props.match.params.id;
    const datosEnviar = {
      email: this.state.email,
      pass1: this.state.pass1,
      pass2: this.state.pass2,
      codigo: IdParam,
    };
    //console.log(datosEnviar);
    this.setState({
      error: null,
      success: null,
      cargando: 1,
    });

    fetch(api + "User/?cambiar_pass1=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            cargando: null,
          });
        } else {
          this.setState({
            error: datosRespuesta.error,
            success: null,
            cargando: null,
          });
        }
        // console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    return (
      <div className="login">
        <div className="cont-login">
          <div className="img-login">
            <img src="img/logo-couland.png" alt="Logo de Coulant"></img>
          </div>
          <br></br>
          <br></br>
          <div className="login-form">
            <div className="alert alert-info" role="alert">
              <h3>Recuperar contraseña</h3>
              <hr></hr>
              <ul>
                <li>1. Verifique su correo electrónico.</li>
                <li>2. Escribir y verificar su nueva contraseña.</li>
              </ul>
            </div>
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="input-wrapper">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  required
                  placeholder="Verificar Correo Electrónico"
                  onChange={this.handleChange}
                  value={this.state.email}
                ></input>
                <PersonIcon className="input-icon" />
              </div>
              <div className="input-wrapper">
                <input
                  type="password"
                  name="pass1"
                  className="form-control"
                  required
                  placeholder="Escribir Contraseña"
                  onChange={this.handleChange}
                  value={this.state.pass1}
                ></input>
                <PersonIcon className="input-icon" />
              </div>
              <div className="input-wrapper">
                <input
                  type="password"
                  name="pass2"
                  className="form-control"
                  required
                  placeholder="Verficar Contraseña"
                  onChange={this.handleChange}
                  value={this.state.pass2}
                ></input>
                <PersonIcon className="input-icon" />
              </div>
              {this.state.cargando ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-primary" role="alert">
                      ENVIANDO
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.success ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-success" role="alert">
                      SE CAMBIO CORRECTAMENTE SU CONTRASEÑA CORRECTAMENTE
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.error ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-danger" role="alert">
                      {this.state.error}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="login-boton">
                <button className="btn btn-primary">
                  Recuperar mi contraseña
                </button>
              </div>
              <hr></hr>

              <div className="login-register">
                <Link to="/">Volver a Inicio de Sesión</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Restablecer);
