import React from "react";
import { Link } from "react-router-dom";
import Redes from "./Redes";
import Whatsapp from "./Whatsapp";

export default function MenuCliente() {
    return (

        <div class="">
            <div className="inicio-menu">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <Link to="/home" className="nav-link active">
                            Mi Perfil
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/mis-datos" className="nav-link">
                            Mis Datos
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/mis-datos-editar" className="nav-link">
                            Editar Perfil
                        </Link>
                    </li>
                </ul>
            </div>
            <Redes />
            <Whatsapp />
        </div>
    );
}

