import React from "react";
import "../css/login.css";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import api from "../servicios/api";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Redes from "../comp/Clientes/Redes";
import Whatsapp from "../comp/Clientes/Whatsapp";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      cargando: null,
      email: "",
      pass1: "",
      showPass: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  BtnShowPass() {
    if (this.state.showPass === 1) {
      //console.log("Contraseña Ocultada");
      this.setState({
        showPass: null,
      });
    } else {
      //console.log("Contraseña Mostrada");
      this.setState({
        showPass: 1,
      });
    }
  }

  cargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    /*Valida sesion*/
    if (IdUser) {
      //Sesion existe
      window.location.href = "./#/home";
    }
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      email: this.state.email,
      pass1: this.state.pass1,
    };
    //console.log(datosEnviar);
    this.setState({
      error: null,
      success: null,
      cargando: 1,
    });

    fetch(api + "User/?login=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            cargando: null,
          });
          const cookie = new Cookies();
          var cookieexpires = new Date(Date.now() + 864000000000);

          cookie.set("UserId", datosRespuesta.data.id, { path: "/" , expires:cookieexpires});
          cookie.set("UserName", datosRespuesta.data.user_name, { path: "/" , expires:cookieexpires});
          cookie.set("UserEmail", datosRespuesta.data.user_email, {
            path: "/", expires:cookieexpires
          });
          cookie.set("CargoId", datosRespuesta.data.cargo_id, {
            path: "/", expires:cookieexpires
          });
          cookie.set("UserPunto", datosRespuesta.data.user_puntos, {
            path: "/", expires:cookieexpires
          });
          cookie.set("UserSaldo", datosRespuesta.data.user_saldo, {
            path: "/", expires:cookieexpires
          });

          window.location.href = "./#/home";
        } else {
          this.setState({
            error: datosRespuesta.error,
            success: null,
            cargando: null,
          });
        }
        console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    return (
      <div className="login">
        <div className="cont-login">
          <div className="img-login">
            <img src="img/logo-couland.png" alt="Logo de Coulant"></img>
          </div>
          <br></br>
          <br></br>
          <div className="login-form">
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="input-wrapper">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  required
                  placeholder="Usuario"
                  onChange={this.handleChange}
                  value={this.state.email}
                ></input>
                <PersonIcon className="input-icon" />
              </div>
              <div className="inp-pass">
                <div className="input-wrapper">
                  <input
                    type={this.state.showPass ? "text" : "password"}
                    name="pass1"
                    className="form-control"
                    required
                    placeholder="Contraseña"
                    onChange={this.handleChange}
                    value={this.state.pass1}
                  ></input>
                  <LockIcon className="input-icon" />
                  <div className="show-pass" onClick={() => this.BtnShowPass()}>
                    {this.state.showPass ? (
                      <VisibilityOffIcon />
                    ) : (
                      <RemoveRedEyeIcon />
                    )}
                  </div>
                </div>
              </div>
              {this.state.cargando ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-primary" role="alert">
                      PROCESANDO
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.success ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-success" role="alert">
                      PERMISO CONCEDIDO
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.error ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-danger" role="alert">
                      {this.state.error}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="login-boton">
                <Link to="/recuperar-pass"> ¿Has olvidado la contraseña? </Link>
                <button className="btn btn-primary">Iniciar Sesión</button>
              </div>
              <hr></hr>

              <div className="login-register">
                <Link to="/registro">Crear una cuenta</Link>
              </div>
            </form>
            <Redes />
            <Whatsapp />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
